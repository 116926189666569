import React, {Component} from "react";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import {Alert, Container, Modal} from "reactstrap";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import Footer from "../../components/Footers/Footer";
import Button from "reactstrap/es/Button";

class Lottery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            input6: "",
            input11: "",
            input12: "",
            input13: "",
            input14: "",
            modal: false,
            modal2: false,
            is_correct: false,
        }
        this.input1 = this.utilizeFocus()
        this.input2 = this.utilizeFocus()
        this.input3 = this.utilizeFocus()
        this.input4 = this.utilizeFocus()
        this.input5 = this.utilizeFocus()
        this.input6 = this.utilizeFocus()
        this.input11 = this.utilizeFocus()
        this.input12 = this.utilizeFocus()
        this.input13 = this.utilizeFocus()
        this.input14 = this.utilizeFocus()
    }

    componentDidMount() {
        this.input1.setFocus();
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("assets/img/landing1Moto.png") + ")",
                    }}
                >
                    <Container>
                        <Row className={"text-center"}>
                            <Col>
                                <h1 className={"text-dark text-center title"}>Sorteo:</h1>
                            </Col>
                        </Row>
                        <br/>
                        <Row className={"text-center mb-3"}>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input1}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input2.setFocus();
                                                }
                                                this.setState({
                                                    input1: e.target.value
                                                });
                                            }}
                                        ref={this.input1.ref}
                                        pattern="[A-Z]"
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input2}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input3.setFocus();
                                                }
                                                this.setState({
                                                    input2: e.target.value
                                                })
                                            }}
                                        ref={this.input2.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input3}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input4.setFocus();
                                                }
                                                this.setState({
                                                    input3: e.target.value
                                                })
                                            }}
                                        ref={this.input3.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input4}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input5.setFocus();
                                                }
                                                this.setState({
                                                    input4: e.target.value
                                                })
                                            }}
                                        ref={this.input4.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input5}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input6.setFocus();
                                                }
                                                this.setState({
                                                    input5: e.target.value
                                                })
                                            }}
                                        ref={this.input5.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input6}
                                        ref={this.input6.ref}
                                        onChange={
                                            (e) => {
                                                this.setState({
                                                    input6: e.target.value
                                                });
                                                if (e.target.value.length >= 1) {
                                                    setTimeout(
                                                        () => this.verifierPlace(),
                                                        300
                                                    )
                                                }
                                            }}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row className={"text-center"}>
                            <Col>
                                <Button size={"lg"} color={"success"}
                                        disabled={!(this.state.input1 && this.state.input2 && this.state.input3
                                            && this.state.input4 && this.state.input5 && this.state.input6)}
                                        onClick={() => this.verifierPlace()}
                                >
                                    Verificar Placa
                                </Button>
                            </Col>
                        </Row>
                        <Modal isOpen={this.state.modal} toggle={() => this.setModal(false)} className="modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Resultado
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.setModal(false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.is_correct ? (
                                    <Alert color={"success"}>
                                        <Row>
                                            <Col sm={"1"}>
                                                <h2><b>:)</b></h2>
                                            </Col>
                                            <Col>

                                                <h2 className={"text-center"}>La placa <b>
                                                    {this.state.input1.toUpperCase()}
                                                    {this.state.input2.toUpperCase()}
                                                    {this.state.input3.toUpperCase()}-
                                                    {this.state.input4.toUpperCase()}
                                                    {this.state.input5.toUpperCase()}
                                                    {this.state.input6.toUpperCase()}
                                                </b> es correcta</h2>

                                            </Col>
                                        </Row>
                                    </Alert>
                                ) : (
                                    <>
                                        <Row>
                                            <Col sm={"1"}>
                                                <h2><b>:(</b></h2>
                                            </Col>
                                            <Col>
                                                <h2 className={"text-center"}>La placa <b>
                                                    {this.state.input1.toUpperCase()}
                                                    {this.state.input2.toUpperCase()}
                                                    {this.state.input3.toUpperCase()}-
                                                    {this.state.input4.toUpperCase()}
                                                    {this.state.input5.toUpperCase()}
                                                    {this.state.input6.toUpperCase()}
                                                </b> no coincide</h2>
                                            </Col>
                                        </Row>
                                        <h4 className={"text-center"}>Intenta de nuevo</h4>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <Button
                                    className="btn-link"
                                    color="danger"
                                    onClick={() => this.resetPlace()}
                                    type="button"
                                >
                                    Ingresar nueva placa
                                </Button>
                            </div>

                        </Modal>
                    </Container>
                </div>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("assets/img/promoIphone.png") + ")",
                    }}
                >
                    <Container>
                        <Row className={"text-center"}>
                            <Col>
                                <h1 className={"text-center title"}>Sorteo <b>iPhone 13</b></h1>
                            </Col>
                        </Row>
                        <br/>
                        <Row className={"text-center mb-3"}>
                            <Col sm={"3"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input11}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input12.setFocus();
                                                }
                                                this.setState({
                                                    input11: e.target.value
                                                });
                                            }}
                                        ref={this.input11.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"3"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input12}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input13.setFocus();
                                                }
                                                this.setState({
                                                    input12: e.target.value
                                                });
                                            }}
                                        ref={this.input12.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"3"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input13}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input14.setFocus();
                                                }
                                                this.setState({
                                                    input13: e.target.value
                                                });
                                            }}
                                        ref={this.input13.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"3"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input14}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    setTimeout(
                                                        () => this.verifierNumber(),
                                                        300
                                                    )
                                                }
                                                this.setState({
                                                    input14: e.target.value
                                                });
                                            }}
                                        ref={this.input14.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <Row className={"mb-3"}>
                            <Col>
                                <p className={"text-center text-white"}>*Aplican términos y condiciones</p>
                            </Col>
                        </Row>
                        <Row className={"text-center mb-3"}>
                            <Col>
                                <Button size={"lg"} color={"success"}
                                        disabled={!(this.state.input11 && this.state.input12 && this.state.input13
                                            && this.state.input14)}
                                        onClick={() => this.verifierNumber()}
                                >
                                    Verificar Número
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className={"text-center text-white"}>Imagenes de referencia para el sorteo.</p>
                            </Col>
                        </Row>
                        <Modal isOpen={this.state.modal2} toggle={() => this.setModal2(false)} className="modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Resultados
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.setModal2(false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.is_correct ? (
                                    <Alert color={"success"}>
                                        <Row>
                                            <Col sm={"1"}>
                                                <h2><b>:)</b></h2>
                                            </Col>
                                            <Col>
                                                <h2 className={"text-center"}>El número <b>
                                                    {this.state.input11.toUpperCase()}
                                                    {this.state.input12.toUpperCase()}
                                                    {this.state.input13.toUpperCase()}
                                                    {this.state.input14.toUpperCase()}
                                                </b> es correcto</h2>
                                            </Col>
                                        </Row>
                                    </Alert>
                                ) : (
                                    <>
                                        <Row>
                                            <Col sm={"1"}>
                                                <h2><b>:(</b></h2>
                                            </Col>
                                            <Col>
                                                <h2 className={"text-center"}>El número <b>
                                                    {this.state.input11.toUpperCase()}
                                                    {this.state.input12.toUpperCase()}
                                                    {this.state.input13.toUpperCase()}
                                                    {this.state.input14.toUpperCase()}
                                                </b> no coincide</h2>
                                            </Col>
                                        </Row>
                                        <h4 className={"text-center"}>Intenta de nuevo</h4>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <Button
                                    className="btn-link"
                                    color="danger"
                                    onClick={() => this.resetNumber()}
                                    type="button"
                                >
                                    Ingresar nuevo número
                                </Button>
                            </div>

                        </Modal>
                    </Container>
                </div>
                <Footer/>
            </>
        );
    }

    utilizeFocus = () => {
        const ref = React.createRef()
        const setFocus = () => {
            ref.current && ref.current.focus()
        }
        return {setFocus, ref}
    }

    verifierPlace = () => {
        if (this.state.input1 && this.state.input2 && this.state.input3 && this.state.input4 && this.state.input5
            && this.state.input6) {
            if (this.state.input1.toUpperCase() + this.state.input2.toUpperCase() + this.state.input3.toUpperCase() +
                this.state.input4.toUpperCase() + this.state.input5.toUpperCase() +
                this.state.input6.toUpperCase() === "MUK90E") {
                this.setState({
                    is_correct: true
                })
            } else {
                this.setState({
                    is_correct: false
                })
            }

            this.setModal(true);
        }
    }

    verifierNumber = () => {
        if (this.state.input11 && this.state.input12 && this.state.input13 && this.state.input14) {
            if (this.state.input11 + this.state.input12 + this.state.input13 + this.state.input14 === "9608") {
                this.setState({
                    is_correct: true
                })
            } else {
                this.setState({
                    is_correct: false
                })
            }
            this.setModal2(true);
        }
    }

    setModal2 = (modal2) => {
        this.setState({modal2})
    }

    setModal = (modal) => {
        this.setState({modal})
    }

    resetPlace = () => {
        this.setState({
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            input6: "",
            modal: false
        });
        setTimeout(
            () => this.input1.setFocus(),
            600
        )
    }

    resetNumber = () => {
        this.setState({
            input11: "",
            input12: "",
            input13: "",
            input14: "",
            modal2: false
        });
        setTimeout(
            () => this.input11.setFocus(),
            600
        )
    }
}

export default Lottery;