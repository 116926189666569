import React, {Component} from "react";

import {Row, Container} from "reactstrap";

class Footer extends Component{
  render(){
    return (
        <footer className="footer section-dark">
          <Container>
            <Row>
              <div className={"col-sm-2 text-white-50"}>
                <h4 className={"text-white"}>UA Company</h4>
                <ul className={"list-unstyled"}>
                  <li>Reservas</li>
                  <li>Experiencias</li>
                  <li>
                    <a className={"text-white"}
                       href={"/clients"}
                    >
                      Registro
                    </a>
                  </li>
                  <li>Sobre nosotros</li>
                </ul>
              </div>
              <div className={"col-sm-3 text-white-50"}>
                <h4 className={"text-white"}>Ubicación</h4>
                <ul className={"list-unstyled"}>
                  <li>Av Calle 26 # 68C-61 - Oficina 734 T.C.D</li>
                  <li>Horarios: L-V 9am-8pm - S 9am-6pm</li>
                </ul>
              </div>
              <div className={"col-sm-4 text-white-50"}>
                <h4 className={"text-white"}>Contacto</h4>
                <ul className={"list-unstyled"}>
                  <li>
                    <b>Teléfono: </b>
                    <a href={"tel:0317031320"}
                       className={"text-white"}
                    >
                      (1) 703 1320
                    </a>
                  </li>
                  <li>
                    <b>Cel: </b>
                    <a href={"tel:3142264654"}
                       className={"text-white"}
                    >
                      314 226 4654
                    </a>
                  </li>
                  <li>
                    <b>Email: </b>
                    <a href={"mailto:reservas@unitedalliance.com.co"}
                       className={"text-white"}
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      reservas@unitedalliance.com.co
                    </a>
                  </li>
                </ul>
              </div>
              <div className={"col-sm-3 text-white-50"}>
                <h4 className={"text-white"}>Nuestras Redes</h4>
                <ul className={"list-unstyled"}>
                  <li>
                    <a className={"text-white"}
                       href={"https://www.facebook.com/UnitedAllianceCol"}
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook" /> /UnitedAllianceCol</a>
                  </li>
                  <li>
                    <a className={"text-white"}
                       href="https://www.instagram.com/UnitedAllianceCol"
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram" /> @UnitedAllianceCol
                    </a>
                  </li>
                  <li>
                    <a className={"text-white"}
                       href={"https://wa.me/573142264654?text=Hola United Alliance"}
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      <i className="fa fa-whatsapp" /> UnitedAllianceSAS
                    </a>
                  </li>
                </ul>
              </div>
            </Row>
            <Row>
              <nav className="footer-nav">
                <ul>
                  <li>
                    <a
                        href="/index"
                    >
                      Inicio
                    </a>
                  </li>
                  <li>
                    <a
                        href="/privacy"
                    >
                      Política de privacidad
                    </a>
                  </li>
                  <li>
                    UNITED ALLIANCE SAS
                  </li>
                  <li>
                    <a href={"https://rnt.confecamaras.co/ver-detalle/04/565785"}
                           target="_blank"
                           rel="noopener noreferrer"
                    >
                      Registro Nacional de Turismo N.<b className={"text-white"}> 88785</b>
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="credits ml-auto">
            <span className="copyright">
              unitedalliance.com.co © {new Date().getFullYear()}
            </span>
              </div>
            </Row>
          </Container>
        </footer>
    )
  }
}

export default Footer;
