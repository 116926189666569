import React, {Component} from "react";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import {Alert, Container, Modal} from "reactstrap";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import Card from "reactstrap/es/Card";
import Button from "reactstrap/es/Button";
import Footer from "../../components/Footers/Footer";

class Lottery40MUnited extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            modal: false,
            is_correct: false,
        }
        this.input1 = this.utilizeFocus()
        this.input2 = this.utilizeFocus()
        this.input3 = this.utilizeFocus()
        this.input4 = this.utilizeFocus()
        this.input5 = this.utilizeFocus()
    }

    componentDidMount() {
        this.input1.setFocus();
    }

    render() {
        return (
            <>
                <IndexNavbar/>
                <div
                    className="page-header"
                    style={{
                        backgroundImage: "url(" + require("assets/img/image-pesos-col.jpeg") + ")",
                    }}
                >
                    <Container>
                        <Row className={"text-center"}>
                            <Col>
                                <h1 className={"text-dark text-center title"}>Sorteo:</h1>
                            </Col>
                        </Row>
                        <br/>
                        <Row className={"text-center mb-3"}>
                            <Col sm={"1"}></Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input1}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input2.setFocus();
                                                }
                                                this.setState({
                                                    input1: e.target.value
                                                });
                                            }}
                                        ref={this.input1.ref}
                                        pattern="[A-Z]"
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input2}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input3.setFocus();
                                                }
                                                this.setState({
                                                    input2: e.target.value
                                                })
                                            }}
                                        ref={this.input2.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input3}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input4.setFocus();
                                                }
                                                this.setState({
                                                    input3: e.target.value
                                                })
                                            }}
                                        ref={this.input3.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input4}
                                        onChange={
                                            (e) => {
                                                if (e.target.value.length >= 1) {
                                                    this.input5.setFocus();
                                                }
                                                this.setState({
                                                    input4: e.target.value
                                                })
                                            }}
                                        ref={this.input4.ref}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                            <Col sm={"2"}>
                                <Card className="bg-light" style={{minHeight: "120px"}}>
                                    <input
                                        type={"text"}
                                        style={{
                                            minHeight: "120px",
                                            fontSize: "60px",
                                            textTransform: "uppercase",
                                            borderRadius: "7px"
                                        }}
                                        className={"text-center"}
                                        maxLength="1"
                                        value={this.state.input5}
                                        ref={this.input5.ref}
                                        onChange={
                                            (e) => {
                                                this.setState({
                                                    input5: e.target.value
                                                });
                                                if (e.target.value.length >= 1) {
                                                    setTimeout(
                                                        () => this.verifierNumber(),
                                                        300
                                                    )
                                                }
                                            }}
                                        onFocus={e => e.target.select()}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row className={"text-center"}>
                            <Col>
                                <Button size={"lg"} color={"success"}
                                        disabled={!(this.state.input1 && this.state.input2 && this.state.input3
                                            && this.state.input4 && this.state.input5)}
                                        onClick={() => this.verifierNumber()}
                                >
                                    Verificar Numero
                                </Button>
                            </Col>
                        </Row>
                        <Modal isOpen={this.state.modal} toggle={() => this.setModal(false)} className="modal-lg">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Resultado
                                </h5>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.setModal(false)}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.is_correct ? (
                                    <Alert color={"success"}>
                                        <Row>
                                            <Col sm={"1"}>
                                                <h2><b>:)</b></h2>
                                            </Col>
                                            <Col>

                                                <h2 className={"text-center"}>El número <b>
                                                    {this.state.input1.toUpperCase()}
                                                    {this.state.input2.toUpperCase()}
                                                    {this.state.input3.toUpperCase()}
                                                    {this.state.input4.toUpperCase()}
                                                    {this.state.input5.toUpperCase()}
                                                </b> es correcta</h2>

                                            </Col>
                                        </Row>
                                    </Alert>
                                ) : (
                                    <>
                                        <Row>
                                            <Col sm={"1"}>
                                                <h2><b>:(</b></h2>
                                            </Col>
                                            <Col>
                                                <h2 className={"text-center"}>No es el número <b>
                                                    {this.state.input1.toUpperCase()}
                                                    {this.state.input2.toUpperCase()}
                                                    {this.state.input3.toUpperCase()}
                                                    {this.state.input4.toUpperCase()}
                                                    {this.state.input5.toUpperCase()}
                                                </b> </h2>
                                            </Col>
                                        </Row>
                                        <h4 className={"text-center"}>Intenta de nuevo</h4>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <Button
                                    className="btn-link"
                                    color="danger"
                                    onClick={() => this.resetPlace()}
                                    type="button"
                                >
                                    Ingresar nuevo numero
                                </Button>
                            </div>

                        </Modal>
                    </Container>
                </div>
                <Footer/>
            </>
        );
    }

    utilizeFocus = () => {
        const ref = React.createRef()
        const setFocus = () => {
            ref.current && ref.current.focus()
        }
        return {setFocus, ref}
    }

    verifierNumber = () => {
        if (this.state.input1 && this.state.input2 && this.state.input3 && this.state.input4 && this.state.input5) {
            if (this.state.input1.toUpperCase() + this.state.input2.toUpperCase() + this.state.input3.toUpperCase() +
                this.state.input4.toUpperCase() + this.state.input5.toUpperCase() === "MUK90E") {
                this.setState({
                    is_correct: true
                })
            } else {
                this.setState({
                    is_correct: false
                })
            }

            this.setModal(true);
        }
    }

    setModal = (modal) => {
        this.setState({modal})
    }

    resetPlace = () => {
        this.setState({
            input1: "",
            input2: "",
            input3: "",
            input4: "",
            input5: "",
            modal: false
        });
        setTimeout(
            () => this.input1.setFocus(),
            600
        )
    }
}

export default Lottery40MUnited;