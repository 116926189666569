import React from "react";

// reactstrap components
import {Button, Card, Form, Input, Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "../../components/Footers/Footer";

function LoginPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div
        className="page-header"
        style={{
          backgroundImage: "url(" + require("assets/img/login-image.jpg") + ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto bg-dark">
                <h3 className="title mx-auto">Bienvenido</h3>
                <Form className="register-form">
                  <label>Correo o usuario</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Correo o usuario" type="email" />
                  </InputGroup>
                  <label>Contraseña</label>
                  <InputGroup className="form-group-no-border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Contraseña" type="password" />
                  </InputGroup>
                  <Button
                      block
                      className="btn-round"
                      color="warning"
                      type="button"
                  >
                    Iniciar Sesión
                  </Button>
                </Form>
                <div className="forgot">
                  <Button
                    className="btn-link"
                    color="danger"
                    href="#forgot"
                    onClick={(e) => e.preventDefault()}
                  >
                    Olvido su contraseña?
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </>
  );
}

export default LoginPage;
