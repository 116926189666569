import React, {Component} from "react";

import {Row, Container} from "reactstrap";

class Footer extends Component{
  render(){
    return (
        <footer className="footer ">
          <Container>
            <Row>
              <nav className="footer-nav">
                <ul>
                  <li>
                    <a
                        href="/documents"
                    >
                      Inicio
                    </a>
                  </li>
                  <li>
                    <a
                        href="#/privacy"
                    >
                      Política de privacidad
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="credits ml-auto">
            <span className="copyright">
              Andres Diaz Hernandez © {new Date().getFullYear()}
            </span>
              </div>
            </Row>
          </Container>
        </footer>
    )
  }
}

export default Footer;
