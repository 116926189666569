import React, {Component} from "react";
import {Card, Col, Container, Row} from "reactstrap";

import promo1 from "assets/img/promosMayo2022/Horizontal1.jpeg";
import promo2 from "assets/img/promosMayo2022/Horizontal2.jpeg"
import promo3 from "assets/img/promosMayo2022/Horizontal3.jpeg"
import promo4 from "assets/img/promosMayo2022/Vertical1.jpeg"
import promo5 from "assets/img/promosMayo2022/Vertical2.jpeg"
import promo6 from "assets/img/promosMayo2022/Vertical3.jpeg"
import promo7 from "assets/img/promosMayo2022/Vertical11.jpeg"
import promo8 from "assets/img/promosMayo2022/Vertical12.jpeg"
import promo9 from "assets/img/promosMayo2022/Vertical12.jpeg"
import Button from "reactstrap/es/Button";

class SectionPlanes extends Component{
    render() {
        return(
            <>
                <div className="section">
                    <Container>
                        <h2 className="text-center">Nuestros planes</h2>
                        <h4 className={"text-center"}>Accede a planes exclusivos para ti</h4>
                        <br/>
                        <Row>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo4} alt={"Plan San Andrés"}/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo5} alt={"Cartagena"}/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo6} alt={"Cartagena"}/>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo7} alt={"Plan Santa Marta"}/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo8} alt={"Rivera Maya"}/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo9} alt={"Cartagena"}/>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo3} alt={"Plan Santa Marta"}/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo2} alt={"Cancun"}/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <img src={promo1} alt={"Bahia Solano"}/>
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <div className={"text-center"}>
                            <Button className="btn-round btn-lg btn-center"
                                    color={"success"}
                                    href={"https://wa.me/573142264654?text=Hola United Alliance"}
                                    target="_blank"
                            >
                                Muchos mas planes escribiendonos a nuestra linea de reservas &nbsp;&nbsp;
                                <i className="fa fa-whatsapp" /> 314 226 4654
                            </Button>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}
export default SectionPlanes;