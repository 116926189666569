import React, {Component} from 'react';
import Button from "reactstrap/es/Button";
import Card from "reactstrap/es/Card";
import { UncontrolledTooltip } from 'reactstrap';

class ImageSource extends Component{
    
    constructor(props) {
        super(props);
        this.state = {
            original_image: null
        }
    }
    
    componentDidMount() {
        if(this.props.preload){
            var img = new Image();
            img.src = this.props.image;
            img.setAttribute('crossOrigin', 'anonymous');
            img.onload = (() => {
                let canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                let dataURL = canvas.toDataURL("image/jpeg", 1);
                this.props.setBaseImage(dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
                this.props.setSourceImage(dataURL);
                this.setState({original_image: dataURL})
            });
        }
    }
    
    render() {
        return(
            <Card className={"text-center"} style={{height:25+'rem'}}>
                <div className="card-header">
                    {this.props.name}
                </div>
                <div className={"card-body"}>
                    <div className="text-center">
                        <img src={this.props.image} className="rounded" alt={this.props.name}
                             style={{height:15+'rem'}}
                        />
                    </div>
                </div>
                <div className={"upload-btn-wrapper"}>
                    <Button className="btn btn-primary" style={{width:100+'%', textAlign:'left'}}>
                        Select {this.props.name}
                    </Button>
                    <input type="file" name={this.props.name}
                           style={{
                               FontSize: 0+'%',
                               position: "absolute",
                               left: 0,
                               bottom: 0,
                               opacity: 0,
                               width: 100+'%',
                               height: 100+"%"
                           }} onChange={event => this.saveLocalFile(event)}
                           accept={"image/jpeg,image/jpg"} capture/>
                    <Button className="btn btn-info" id={this.props.name.replace(/ /g, "")}
                            style={{
                                position: "absolute",
                                right: 0,
                                bottom: 0,
                                width:60+'px'
                            }}
                            onClick={() => this.rotateImage()}
                    >
                        <i className="nc-icon nc-refresh-69"></i>
                    </Button>
                    <UncontrolledTooltip placement='left' target={this.props.name.replace(/ /g, "")}>
                        Rotate right
                    </UncontrolledTooltip>
                </div>
            </Card>
        )
    }
    
    rotateImage() {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext("2d");
        var image = new Image();
        image.src = this.state.original_image;
        let cw = image.height;
        let ch = image.width;
        let cy = image.height * (-1);
        image.onload = () => {
            canvas.setAttribute('width', cw);
            canvas.setAttribute('height', ch);
            ctx.rotate(90 * Math.PI / 180);
            ctx.drawImage(image, 0, cy);
            let dataURL = canvas.toDataURL("image/jpeg", 1);
            this.props.setSourceImage(dataURL);
            this.setState({original_image: dataURL})
            this.resizeImage(dataURL)
        };
    }

    saveLocalFile(event) {
        let reader = new FileReader();
        let image = event.target.files[0];
        reader.onload = () => {
            let base64Image = reader.result;
            this.setState({original_image: base64Image});
            this.props.setSourceImage(base64Image);
            //this.props.setBaseImage(base64Image.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
            this.resizeImage(base64Image)
        }
        if(image){
            reader.readAsDataURL(image);
        }
        
    }
    
    resizeImage(base64Image){
        let tam_liv = 1080;
        let img = new Image();
        img.src = base64Image;
        img.onload = () =>{
            let width =  img.width;
            let height = img.height;
            let tam = this.props.size ? this.props.size: tam_liv;
            if(img.width> tam){
                let porc = tam/width;
                height = porc * height;
                width = tam;
            }
            let canvas = document.createElement('canvas')
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            let dataURL = canvas.toDataURL("image/jpeg", 1);
            this.props.setBaseImage(dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""));
        }
    }
}
export default ImageSource;