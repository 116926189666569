import React from "react";

import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import logo3 from "assets/img/logos/LogoBlancoV2.png"

import FloatButton from "../Button/FloatButton";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [logo, setLogo] = React.useState(logo3);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("bg-info");
        setLogo(logo3)
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
        setLogo(logo3)
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <FloatButton/>
        <div className="navbar-translate">
          <a href={"/index"}>
            <img src={logo} height={34}
                 data-placement="bottom"
                 alt={"United Alliance"}
            />
          </a>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            {
              //<NavItem>
                //<Button className={"btn-warning"}>
                  //Reserva aqui
                //</Button>
              //</NavItem>
            }

            <NavItem>
              <NavLink
                  data-placement="bottom"
                  href="https://www.facebook.com/UnitedAllianceCol"
                  target="_blank"
                  title="Siguenos en Facebook"
              >
                <i className="fa fa-facebook" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/UnitedAllianceCol"
                target="_blank"
                title="Siguenos en Instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  data-placement="bottom"
                  href="https://wa.me/573142264654?text=Hola United Alliance"
                  target="_blank"
                  title="Escribanos a Whatsapp"
              >
                <i className="fa fa-whatsapp" />
                <p className="d-lg-none">Whatsapp</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
