import React from "react";
import {Col, Container, Row} from "reactstrap";
import ImageSource from "../../components/ImageSources/ImageSource";

import card from "../../assets/img/address-card-solid.svg";
import HttpOperations from "../../controller/HttpOperations";
import Button from "reactstrap/es/Button";
import DocumentsNavbar from "../../components/Navbars/DocumentsNavbar";
import FooterDocuments from "../../components/Footers/FooterDocuments";

class DocumentOperation extends HttpOperations{

    constructor(props) {
        super(props);
        this.state = {
            front_img: card,
            back_img: card,
            front: null,
            back: null,
            cameras: null,
            currentStream: null,
            loadPhoto: false,
            operations: [],
            endPoint: "/documents"
        };
    }

    componentDidMount() {
        this.selectURL();
    }

    loadPostOrPut() {
        let request = {
            document: {
                front: this.state.front,
                back: this.state.back
            },
        }
        this.loadPostOrPutAction(request);
    }

    render() {
        return(
            <>
                <DocumentsNavbar/>
                <div className={"main"}>
                    <div className={"section section-dark-blue"}>
                        <Container>
                            <br/>
                            <Row>
                                <Col lg="6">
                                    <ImageSource name={"Front ID"} image={this.state.front_img}
                                                 setSourceImage={i => this.setState({
                                                     front_img: i
                                                 })}
                                                 loadNewPhoto={() => this.loadPostOrPut()}
                                                 setBaseImage={i => this.setState({
                                                     front: i
                                                 })}
                                                 size={1280}
                                                 preload={false}
                                    />
                                </Col>
                                <Col lg="6">
                                    <ImageSource name={"Back ID"} image={this.state.back_img}
                                                 setSourceImage={i => this.setState({
                                                     back_img: i
                                                 })}
                                                 loadNewPhoto={() => this.loadPostOrPut()}
                                                 setBaseImage={i => this.setState({
                                                     back: i
                                                 })}
                                                 size={1280}
                                                 preload={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Button className={"btn btn-success"} style={{width: 100 + '%'}}
                                            onClick={() => this.loadPostOrPut()}
                                    >
                                        Enviar
                                    </Button>
                                </Col>
                            </Row>
                            {this.loadProgress()}
                            {this.loadResult()}
                        </Container>
                    </div>
                </div>
                <FooterDocuments/>
            </>
        )
    }
}
export default DocumentOperation;