import React, {Component} from "react"
import {Container, Row, Col} from "reactstrap";
import InstagramEmbed from "react-instagram-embed";

class SectionExperiences extends Component{
    render() {
        return(
            <>
                <div className="section">
                    <Container>
                        <h2 className="text-center title">Experiencias con United Alliance</h2>
                        <Row>
                            <Col className="ml-auto mr-auto" md="6">
                                <InstagramEmbed
                                    url='https://www.instagram.com/p/CMI3v7-H058/'
                                    clientAccessToken='EAAGPbwuz5sABALtukiR8p4HuG4itBKIn6vEKFEEEuy3uyZAs1kmLsMBVu3ffRiN8WYuGjQPZBV02KDKRc2NwOfpQlDYQqumiu0eRmfkkKc9Sl5rcTF9WwZBvgBKZBeY0auNl2hrWZCVLLEzMCSFBROWDLX2n4Lb7wRhNQV5CNZBmsv3VmjCZAZB2A2eqbzHxBZCuk5Ef7JX2VZB78JARJjwDAY'
                                />
                                <InstagramEmbed
                                    url='https://www.instagram.com/p/CMK3oWUlYMe/'
                                    clientAccessToken='EAAGPbwuz5sABALtukiR8p4HuG4itBKIn6vEKFEEEuy3uyZAs1kmLsMBVu3ffRiN8WYuGjQPZBV02KDKRc2NwOfpQlDYQqumiu0eRmfkkKc9Sl5rcTF9WwZBvgBKZBeY0auNl2hrWZCVLLEzMCSFBROWDLX2n4Lb7wRhNQV5CNZBmsv3VmjCZAZB2A2eqbzHxBZCuk5Ef7JX2VZB78JARJjwDAY'
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="6">
                                <InstagramEmbed
                                    url='https://www.instagram.com/p/CMdQuY2Hlas/'
                                    clientAccessToken='EAAGPbwuz5sABALtukiR8p4HuG4itBKIn6vEKFEEEuy3uyZAs1kmLsMBVu3ffRiN8WYuGjQPZBV02KDKRc2NwOfpQlDYQqumiu0eRmfkkKc9Sl5rcTF9WwZBvgBKZBeY0auNl2hrWZCVLLEzMCSFBROWDLX2n4Lb7wRhNQV5CNZBmsv3VmjCZAZB2A2eqbzHxBZCuk5Ef7JX2VZB78JARJjwDAY'
                                />
                                <InstagramEmbed
                                    url='https://www.instagram.com/tv/CSccLUHnhBj/'
                                    clientAccessToken='EAAGPbwuz5sABALtukiR8p4HuG4itBKIn6vEKFEEEuy3uyZAs1kmLsMBVu3ffRiN8WYuGjQPZBV02KDKRc2NwOfpQlDYQqumiu0eRmfkkKc9Sl5rcTF9WwZBvgBKZBeY0auNl2hrWZCVLLEzMCSFBROWDLX2n4Lb7wRhNQV5CNZBmsv3VmjCZAZB2A2eqbzHxBZCuk5Ef7JX2VZB78JARJjwDAY'
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
export default SectionExperiences;