
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
import "assets/demo/main.css"
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import LoginPage from "views/Login/LoginPage.js";
import RegisterClients from "./views/landing/RegisterClients";
import Privacy from "./views/landing/Privacy";
import DocumentOperation from "./views/landing/DocumentOperation";
import Lottery from "./views/lottery/Lottery";
import LotteryDreams from "./views/lottery/LotteryDreams";
import Lottery40MUnited from "./views/lottery/Lottery40MUnited";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
          path="/index"
          render={(props) => <Index {...props} />} />
      <Route
          path="/clients"
          render={(props) => <RegisterClients {...props} />}
      />
      <Route
          path="/documents"
          render={(props) => <DocumentOperation {...props}/>}
      />
      <Route
          path="/privacy"
          render={(props) => <Privacy {...props} />}
      />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route
        path="/login"
        render={(props) => <LoginPage {...props} />}
      />
      <Route
          path="/lottery"
          render={(props) => <Lottery {...props} />}
      />
      <Route
          path="/dreams-company"
          render={(props) => <LotteryDreams {...props} />}
      />
      <Route
          path="/lottery40M"
          render={(props) => <Lottery40MUnited {...props} />}
      />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
