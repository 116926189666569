import React, {Component} from "react";
import axios from "axios";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {Alert} from "reactstrap";
import Spinner from "reactstrap/es/Spinner";

class HttpOperations extends Component{
    
    constructor(props) {
        super(props);
        this.state ={
            is_ok_result: false,
            response: "",
            visible_loading: false,
            percentage_loading: 0,
            process_id: null,
            is_fail_result: false,
            request: "",
            url: "",
            partial: null,
            data_process: null,
            access_token: null,
            auth: null
        }
    }
   
    selectURL(){
        this.updateURL(
            'https://j1qc9qsjea.execute-api.us-east-1.amazonaws.com/Prod'
        )
    }
    
    updateURL(url){
        this.setState({
            url: url
        })
    }
    
    loadPostOrPutAction(request) {
        this.setProgress(30);
        this.loadPost(request);
    }
    
    loadPost(request){
        this.setState({
            is_ok_result: false,
            is_fail_result: false,
            response: "",
            data_process: null,
            process_id: null,
            request: request,
            partial: null
        });
        this.loadRequestPost(request);
    }
    
    loadRequestPost(request) {
        axios({
            url: this.state.url + this.state.endPoint,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'json',
            data: request ? request : this.state.request
        }).then( (response) =>{
            this.setProgress(100);
            this.setState({
                process_id: response.data.document,
                is_ok_result: true,
                response: JSON.stringify(response.data, null, 2),
            });
        }).catch( (error) =>{
            this.setState({
                is_ok_result: false,
                is_fail_result: true,
                partial: null,
                response: error,
                data_process: null,
                process_id: null
            });
            this.setProgress(100);
        })
    }

    setProgress(progress){
        if(progress===100){
            this.setState({
                visible_loading: false,
                percentage_loading: 100
            })
        }
        else{
            this.setState({
                visible_loading: true,
                percentage_loading: progress
            })
        }
    }
    
    loadResult() {
        if (this.state.is_ok_result) {
            return (
                <>
                    <Row>
                        <Col lg="12">
                            {this.loadResultsResponse()}
                        </Col>
                    </Row>
                </>
            )
        }
        if (this.state.is_fail_result) {
            return (
                <div className={"text-center"}>
                    <br/>
                    <Alert color={"warning"} style={{color: 'black'}}>
                        <h3>:( Algo salio mal, Intenta nuevamente</h3>
                    </Alert>
                </div>
            )
        } else {
            return (
                <div className={"text-center"}>
                </div>
            )
        }
    }
    
    loadResultsResponse() {

        if(this.state.process_id){
            return(
                <div>
                    <br/>
                    <Alert color={"success"} style={{color: 'black'}}>
                        <h5 className={"text-center"}>Proceso exitoso</h5>
                        <p className="text-center">
                            <b>ProcessId:</b> {this.state.process_id}
                        </p>
                    </Alert>
                </div>
            )
        }

        else {
            return (
                <div className={"text-center"}>
                    <br/>
                    <Spinner color="success" />
                </div>
            )
        }
    }

    loadProgress() {
        if(this.state.visible_loading){
            return(
                <div className={"text-center"}>
                    <br/>
                    <Spinner color="success" />
                </div>
            )
        }
    }

}

export default HttpOperations;