import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import SectionPlanes from "./index-sections/SectionPlanes";
import LandingPageHeader from "../components/Headers/LandingPageHeader";
import SectionBenefits from "./index-sections/SectionBenefits";
import SectionCarousel from "./index-sections/SectionCarousel";
import SectionExperiences from "./index-sections/SectionExperiences";


function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
      <>
        <IndexNavbar />
        <LandingPageHeader/>
        <div className="main">
            <SectionBenefits/>
            <SectionPlanes/>
            <SectionCarousel/>
            <SectionExperiences/>
        </div>
        <Footer />
      </>
  );
}

export default LandingPage;
