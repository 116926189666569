import React from "react";

// core components
import Footer from "components/Footers/Footer.js";
import {Container} from "reactstrap";
import ClientRegisterNavbar from "../../components/Navbars/ClientRegisterNavbar";

class Privacy extends React.Component{

    render() {
        return (
            <>
                <ClientRegisterNavbar/>
                <div className="main">
                    <div className="section ">
                        <Container>
                            <div className={"title"}>
                                <h2>Política de Privacidad</h2>
                            </div>
                            <div>
                                <p className={"text-justify"}>
                                    En cumplimiento a lo estipulado en la ley 1581 de 2012 y a lo previsto en el
                                    numeral 3 y el parágrafo del artículo 10 del Decreto 1377, con el objetivo de
                                    informar a todas las personas cuyos datos personales se encuentran en nuestras
                                    bases de datos, y teniendo en cuenta la imposibilidad de solicitar autorización
                                    en forma individual, United Alliance SAS informa que hace uso del mecanismo
                                    alternativo establecido en el citado numeral y manifiesta que los datos
                                    personales incluidos en nuestras bases de datos se han recompilado para el
                                    desarrollo de diversos procedimientos relacionados directamente con su
                                    objetivo social.
                                </p>
                                <p className={"text-justify"}>
                                    El uso y manejo de los mismos, se efectúa bajo estrictos estándares de
                                    responsabilidad, dentro de los cuales está el respeto al debido proceso y a
                                    la protección de la información.
                                </p>
                                <p className={"text-justify"}>
                                    Los datos registrados en nuestras bases de datos son, entre otros:
                                    reseñas públicas, tales como dirección, teléfono, direcciones de correos
                                    electrónicos. En todo caso, en cualquier momento el titular de la información
                                    podrá revocar su consentimiento y ejercer su derecho a la supresión de
                                    datos personales consagrado en la Ley 1581 de 2012.
                                </p>
                                <p className={"text-justify"}>
                                    Si usted desea consultar información o solicitar la supresión de sus datos
                                    personales de nuestras bases de datos, agradecemos se comunique dentro de los
                                    30 días siguientes a la publicación del presente aviso a la dirección
                                    Av Calle 26 # 69D - 91 Torre 2 Oficina 604 en Bogotá o a los correos electrónicos
                                    administrativo@unitedalliance.com.co. Si decide no hacerlo, vencidos el referido plazo,
                                    consideramos autorizado al titular de información en cualquier tiempo de consultar,
                                    conocer, actualizar sus datos personales, o solicitar su supresión o rectificación.
                                </p>
                            </div>
                        </Container>

                    </div>
                </div>
                <Footer />
            </>
        );
    }

}

export default Privacy;
