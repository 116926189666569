import React, {Component} from "react"
import {Card, Col, Container, Row} from "reactstrap";

class SectionBenefits extends Component {
    render() {
        return(
            <>
                <div className="section bg-primary">
                    <Container>
                        <h2 className="text-center title">Nuestros beneficios</h2>
                        <Row>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <i className="nc-icon nc-camera-compact icon-prom text-center"/>
                                    <h3 className={"text-center"}>
                                        Experiencias
                                    </h3>
                                    <br/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <i className="nc-icon nc-bag-16 icon-prom text-center"/>
                                    <h3 className={"text-center"}>Promociones</h3>
                                    <br/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <i className="nc-icon nc-umbrella-13 icon-prom text-center"/>
                                    <h3 className={"text-center"}>Confianza</h3>
                                    <br/>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <i className="nc-icon nc-credit-card icon-prom text-center"/>
                                    <h3 className={"text-center"}>Ahorro</h3>
                                    <br/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <i className="nc-icon nc-briefcase-24 icon-prom text-center"/>
                                    <h3 className={"text-center"}>Respaldo</h3>
                                    <br/>
                                </Card>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <Card className="page-carousel">
                                    <i className="nc-icon nc-pin-3 icon-prom text-center"/>
                                    <h3 className={"text-center"}>Excelentes destinos</h3>
                                    <br/>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}
export default SectionBenefits;