
import React from "react";

// reactstrap components
import {Button, Container} from "reactstrap";
import image1 from "assets/img/soroush-karimi.jpg"
import image2 from "assets/img/rosario-islas.jpg"
import image3 from "assets/img/federico-beccari.jpg"
import image4 from "assets/img/joshua-stannard.jpg"

// core components

const items = [
  {
    src: image1,
  },
  {
    src: image2,
  },
  {
    src: image3,
  },
  {
    src: image4,
  },
];

function LandingPageHeader() {
  let pageHeader = React.createRef();

  const randomIndex = () => {
    return Math.random() * (items.length - 0);
  }
  const [activeIndex, setActiveIndex] = React.useState(parseInt(randomIndex()));

  const next = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };


  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
    setTimeout(next, 10000)
  }
  );

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url("+items[activeIndex].src+")",
        }}
        className="page-header section-dark"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
              <h1><b>Bienvenidos a la aventura</b></h1>
              <h3><i>"Si puedes soñarlo, puedes lograrlo"</i></h3>
            <br />
            <h3><b>Promociones, ofertas y más</b></h3>
            <p><i>Exclusivo para clientes asociados de UA</i></p>
          </div>
        </Container>
        <div className="category category-absolute">
            <Button className="btn-round btn-just-icon mr-1" color="neutral" type="button">
                  <a href="https://www.facebook.com/UnitedAllianceCol">
                      <i className="fa fa-facebook" />
                  </a>
            </Button>

            <Button className="btn-round btn-just-icon mr-1" color="neutral" type="button">
                  <a href="https://www.instagram.com/UnitedAllianceCol">
                      <i className="fa fa-instagram" />
                  </a>
            </Button>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
