import React, {Component} from "react";

// core components
import Footer from "components/Footers/Footer.js";
import axios from 'axios';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    Row
} from "reactstrap";
import ClientRegisterNavbar from "../../components/Navbars/ClientRegisterNavbar";
import SweetAlert from "react-bootstrap-sweetalert";

class RegisterClients extends Component{

    constructor(props) {
        super(props);
        this.state = {
            name:"",
            lastname:"",
            email:"",
            phone:"",
            accepted: false,
            visible: false,
            alert: null,
            modal: false,
            sender: false,
            sending: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    handleInputChange(event){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        this.setState({
            [name]: value
        });
        if (name==="accepted"){
            this.setState({
                visible: !value
            })
        }
    }
    onDismiss(){
        this.setState({visible: !this.state.visible});
    }
    onConfirm(){
        this.setState({
            alert: null
        });
        window.location = '/clients'
    }
    sendData = e => {
        e.preventDefault();
        if(!this.state.accepted){
            this.setState({
                visible: true
            })
        }
        else {
            this.sendRequest()
        }
    }

    sendRequest(){
        let body = {
            name: this.state.name,
            lastName: this.state.lastname,
            phone: this.state.phone,
            email: this.state.email,
            acceptTerms: this.state.accepted
        }
        this.setState({
            sender: true,
            sending: true
        });
        axios(
            {
                url: "https://n2xime486b.execute-api.us-east-1.amazonaws.com/Prod/clients",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'json',
                data: body
            }
        ).then( response => {
            this.successAlert();
            setTimeout( () => {
                window.location = '/clients'
            }, 2000)

        }).catch( error => {
            this.dangerAlert();
        });
    }

    successAlert(){
        this.setState({
            alert: (
                <SweetAlert success
                            title="Guardado!"
                            onConfirm={() => this.onConfirm()}
                            timeout={2000}
                >
                    Se han registrado tus datos!
                </SweetAlert>
            )
        });
    }
    dangerAlert(){
        this.setState({
            alert: (
                <SweetAlert danger
                            title="Cancelado!"
                            onConfirm={() => this.onConfirm()}
                            timeout={4000}
                >
                    Hay un error al guardar los datos
                </SweetAlert>
            )
        });
    }

    openModal(isOpen) {
        this.setState({
            modal: isOpen
        });
    }

    render() {
        return (
            <>
                <ClientRegisterNavbar/>
                <div className="main">
                    <div className="section "
                         style={{
                             backgroundImage:
                                 "url(" + require("assets/img/rosario-islas.jpg") + ")",
                             backgroundRepeat: "no-repeat",
                             backgroundPosition: "center",
                             backgroundSize: "cover"
                         }}
                    >
                        <Container>
                            <div className={"title"}>

                            </div>
                            <Card>
                                <CardHeader>Registro de cliente</CardHeader>
                                <CardBody>
                                    <Form
                                        onSubmit={this.sendData}
                                    >
                                    <Row>
                                        <FormGroup className="col-md-6">
                                            <Label for={"name"}>Nombres</Label>
                                            <Input type={"text"}
                                                   id={"name"}
                                                   placeholder={"Nombres"}
                                                   onChange={this.handleInputChange}
                                                   required
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-6">
                                            <Label for={"lastname"}>Apellidos</Label>
                                            <Input type={"text"}
                                                   id={"lastname"}
                                                   placeholder={"Apellidos"}
                                                   onChange={this.handleInputChange}
                                                   required
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup className="col-md-6">
                                            <Label for={"phone"}>Celular</Label>
                                            <Input type={"phone"}
                                                   id={"phone"}
                                                   placeholder={"Celular"}
                                                   onChange={this.handleInputChange}
                                                   required
                                            />
                                        </FormGroup>
                                        <FormGroup className="col-md-6">
                                            <Label for={"email"}>Correo Electrónico</Label>
                                            <Input type={"email"}
                                                   id={"email"}
                                                   placeholder={"Correo Electrónico"}
                                                   onChange={this.handleInputChange}
                                            />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup className="col-md-12">
                                            <Label>
                                                <a onClick={() => this.openModal(true)} href={"#terms"}>Leer aqui las condiciones y política de privacidad</a>
                                            </Label>
                                        </FormGroup>
                                    </Row>
                                    <FormGroup check className="col-md-12">
                                        <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss}>
                                            <b></b> Debes aceptar los terminos y condiciones.
                                        </Alert>
                                        <Label className="form-check-label">
                                            <Input className="form-check-input"
                                                   type="checkbox"
                                                   value="false"
                                                   id={"accepted"}
                                                   onChange={this.handleInputChange}
                                                   name={"Terms and conditions"}
                                            />
                                            Acepto el contrato de términos y condiciones y nuestra política de privacidad de datos.
                                            <span className="form-check-sign">
                                                <span className="check"></span>
                                            </span>
                                        </Label>
                                    </FormGroup>
                                        {this.state.alert}
                                    <Button color="success" type={"submit"} disabled={this.state.sender}>Enviar</Button>
                                        <br/>
                                        <br/>
                                        <Alert color="info" isOpen={this.state.sending}>
                                            <b>Enviando datos...</b>
                                        </Alert>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Modal
                                isOpen={this.state.modal}
                                className="modal-lg"
                                toggle={() => this.openModal(false)}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLiveLabel">
                                        Condiciones y política de privacidad
                                    </h5>
                                    <button
                                        aria-label="Close"
                                        className="close"
                                        data-dismiss="modal"
                                        type="button"
                                        onClick={() => this.openModal(false)}
                                    >
                                        <span aria-hidden={true}>×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <h4 className={"title"}>Política de privacidad de datos personales</h4>
                                    <p className={"text-justify"}>
                                        En cumplimiento a lo estipulado en la ley 1581 de 2012 y a lo previsto en el
                                        numeral 3 y el parágrafo del artículo 10 del Decreto 1377, con el objetivo de
                                        informar a todas las personas cuyos datos personales se encuentran en nuestras
                                        bases de datos, y teniendo en cuenta la imposibilidad de solicitar autorización
                                        en forma individual, United Alliance SAS informa que hace uso del mecanismo
                                        alternativo establecido en el citado numeral y manifiesta que los datos
                                        personales incluidos en nuestras bases de datos se han recompilado para el
                                        desarrollo de diversos procedimientos relacionados directamente con su
                                        objetivo social.
                                    </p>
                                    <p className={"text-justify"}>
                                        El uso y manejo de los mismos, se efectúa bajo estrictos estándares de
                                        responsabilidad, dentro de los cuales está el respeto al debido proceso y a
                                        la protección de la información.
                                    </p>
                                    <p className={"text-justify"}>
                                        Los datos registrados en nuestras bases de datos son, entre otros:
                                        reseñas públicas, tales como dirección, teléfono, direcciones de correos
                                        electrónicos. En todo caso, en cualquier momento el titular de la información
                                        podrá revocar su consentimiento y ejercer su derecho a la supresión de
                                        datos personales consagrado en la Ley 1581 de 2012.
                                    </p>
                                    <p className={"text-justify"}>
                                        Si usted desea consultar información o solicitar la supresión de sus datos
                                        personales de nuestras bases de datos, agradecemos se comunique dentro de los
                                        30 días siguientes a la publicación del presente aviso a la dirección
                                        Av Calle 26 # 69D - 91 Torre 2 Oficina 604 en Bogotá o a los correos electrónicos
                                        administrativo@unitedalliance.com.co. Si decide no hacerlo, vencidos el referido plazo,
                                        consideramos autorizado al titular de información en cualquier tiempo de consultar,
                                        conocer, actualizar sus datos personales, o solicitar su supresión o rectificación.
                                    </p>
                                    <h4 className={"title"}>Términos y Condiciones</h4>
                                    <p>
                                        A continuación, se establecen términos y condiciones para sorteo en redes
                                        sociales de United Alliance SAS.
                                    </p>
                                    <p className={"text-justify"}>
                                        1.	Se debe inscribir por algunas de las redes sociales disponibles de
                                        United Alliance SAS dentro de las fechas publicadas del sorteo. Las
                                        redes sociales son Instagram la cuenta @unitedalliancecol y en
                                        Facebook /unitedalliancecol
                                    </p>
                                    <p className={"text-justify"}>
                                        2.	Debe ingresar sus datos dentro del formulario que esta junto a la publicación que promociona el sorteo, los datos son tratados bajo la política de tratamiento de datos publicada en la pagina web de la agencia.
                                    </p>
                                    <p className={"text-justify"}>
                                        3.	Las fechas para la inscripción al sorteo esta publicados dentro del anuncio.
                                    </p>
                                    <p className={"text-justify"}>
                                        4.	El sorteo se realiza el día siguiente de la fecha de finalización de la inscripción, el sorteo se hará seleccionando un único ganador dentro de los registrados por el formulario de inscripción publicado en la pagina web de la compañia.
                                    </p>
                                    <p className={"text-justify"}>
                                        5.	Al ganador se le notificara vía telefónica, donde se le indica las presentes condiciones para acceder al premio.
                                    </p>
                                    <p className={"text-justify"}>
                                        6.	El el ganador se debe acercar a la oficina de United Alliance SAS y así mismo debe adquirir el servicio ofrecido como parte de unos de los clientes oficiales de United Alliance.
                                    </p>
                                    <p className={"text-justify"}>
                                        7.	Para redimir el premio, el ganador debe hacer la reserva por United Alliance, indicando el hotel y el lugar a donde va a viajar o si el premio es el viaje a un lugar espeficio, solo debe hacer la reserva (No es valido para otras agencias de viajes)
                                    </p>
                                    <p className={"text-justify"}>
                                        8.	El lugar para redimir el premio es un destino dentro del territorio colombiano, o si el premio es un lugar especifico, no es necesario hacer la elección.
                                    </p>
                                    <p className={"text-justify"}>
                                        9.	La reserva para el viaje se deba hacer dentro de las temporadas bajas, debe cominicarse con United Alliance para saber cuales con las temporadas disponibles. Si el premio es dentro de una fecha especifica, el cliente debe aceptar hacer el viaje en tales fechas para hacer efectivo el premio.
                                    </p>
                                    <p className={"text-justify"}>
                                        10.	El premio que se entrega es el anunciado para solo la cantidad de personas que se defina el premio incluido persona inscrita a cualquier destino nacional. Si el premio son tiquetes aéreos, estos son validos para cualquiera de los aeropuertos nacionales, exceptuando San Andres y Leticia
                                    </p>
                                    <p className={"text-justify"}>
                                        11.	Si el premio son tiquetes aereos, United Alliance se reserva el derecho a seleccionar la aerolínea donde se van a redimir los tiquetes aéreos.
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <div className="left-side"></div>
                                    <div className="divider" />
                                    <div className="right-side">
                                        <Button
                                            className="btn-link"
                                            color="default"
                                            type="button"
                                            onClick={() => this.openModal(false)}
                                        >
                                            Cerrar
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </Container>

                    </div>
                </div>
                <Footer />
            </>
        );
    }


}

export default RegisterClients;
