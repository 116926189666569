import React, {Component} from "react";
class FloatButton extends Component{
    render(){
        return(
            <a href="https://wa.me/573142264654?text=Hola United Alliance"
               className={"float-button"}
               target="_blank"
               rel={"noopener noreferrer"}
            >
                <i className="fa fa-whatsapp icon-float-button"
                />
            </a>
        );
    }
}
export default FloatButton;